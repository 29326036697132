<div class="edit">
  <app-progress [loading]="loading"></app-progress>
  <div class="edit_header"> Редактирование новости</div>
  <hr/>
  <div class="edit_caption">Заголовок новости:</div>
  <div class="edit_editor">
    <textarea class="edit_editor_title" [(ngModel)]="news.title" ></textarea>
  </div>
  <div class="edit_caption">Текст новости:</div>
  <div class="edit_editor">
    <angular-editor [(ngModel)]="news.body"></angular-editor>
  </div>
  <div class="edit_buttons">
    <div class="edit_buttons_button">
      <button mat-button color="Basic" (click)="goBack()">Отмена</button>
    </div>
    <div class="edit_buttons_button">
      <button mat-button color="Basic" (click)="updateNews()">Сохранить</button>
    </div>
  </div>
  <app-not-found-text [show]="!loading && !news.body"></app-not-found-text>
</div>
