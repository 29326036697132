import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NewsBody, NewsCount, NewsFilter, NewsTitle } from '../../Components/main-news/main-news.component';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

const baseUrl = environment.baseUrl;

//const baseUrl = 'http://localhost:3000';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) {
  }

  public updateNews = (id: number, title: string, body:string): Observable<number> => this.http.post<number>(`${baseUrl}/v1/News/${id}`, { id, title, body });

  public deleteNews = (id: number): Observable<number> => this.http.delete<number>(`${baseUrl}/v1/News/${id}`);

  public getNewsBody = (id: number): Observable<NewsBody> => this.http.get<NewsBody>(`${baseUrl}/v1/News/${id}?replaceLinks=true`);

  public getNewsList = (filter: NewsFilter): Observable<NewsTitle[]> => {
    if (this.isEmpty(filter)) {
      return of([]);
    }
    return this.http.post<NewsTitle[]>(`${baseUrl}/v1/NewsList/`, {
      'endDate': moment(filter.endDate.toString()).format('YYYYDDMM'),
      'startDate': moment(filter.startDate.toString()).format('YYYYDDMM'),
      'isFullSearch': filter.isFullTextSearch,
      'topNewsId': filter.topNewsId,
      'bottomNewsId': filter.bottomNewsId,
      'domain': filter.domain,
      'categories': filter.categories.join(','),
      'searchString': filter.searchString
    });
  }

  public getNewsCount = (filter: NewsFilter): Observable<NewsCount> => {
    if (this.isEmpty(filter)) {
      return of({ newsCount: 0 });
    }
    return this.http.post<NewsCount>(`${baseUrl}/v1/NewsCount/`, {
      'endDate': moment(filter.endDate.toString()).format('YYYYDDMM'),
      'startDate': moment(filter.startDate.toString()).format('YYYYDDMM'),
      'isFullSearch': filter.isFullTextSearch,
      'topNewsId': filter.topNewsId,
      'bottomNewsId': filter.bottomNewsId,
      'domain': filter.domain,
      'categories': filter.categories.join(','),
      'searchString': filter.searchString
    });
  }

  private isEmpty = (f: NewsFilter): boolean => !f || !f.domain || !f.categories || f.categories.length == 0 || !f.startDate || !f.endDate;

}
